<template>
  <div class="repaymen-detail">
    <BaseHeaderBar
      title="Rincian"
      :showCustomerService="false"
      :showBackButton="true"
    />
    <div class="content">
      <div class="top-block">
        <p class="p1">
          {{ repaymentDetail.badrnkName }}（{{
            repaymentDetail.badrnkAccount
          }}）
        </p>
        <p class="p2">Jumlah pinjaman sudah ditransfer ke rekening bank ini</p>
        <p class="p3">Nama Pengguna: {{ repaymentDetail.cudrstomerName }}</p>
      </div>
      <div class="detail-list">
        <!-- 借款金额 -->
        <div class="info">
          <p>Nominal Pinjaman</p>
          <p>Rp{{ formatNumberWithCommas(repaymentDetail.lodranAmount) }}</p>
        </div>
        <!-- 贷款日期 -->
        <div class="info">
          <p>Waktu Pinjaman</p>
          <p>{{ repaymentDetail.ardrrivalTime }}</p>
        </div>
        <!-- 还款日期 -->
        <div class="info">
          <p>Waktu Pembayaran</p>
          <p>{{ repaymentDetail.redrpaymentTime }}</p>
        </div>

        <!-- 贷款期限 -->
        <div class="info">
          <p>Tenor Pinjaman</p>
          <p>{{ repaymentDetail.tidrmers }}</p>
        </div>
        <!-- 逾期天数 -->
        <div class="info">
          <p>Jumlah Hari Keterlambatan</p>
          <p>{{ repaymentDetail.ovdrerdueDay }} Hari</p>
        </div>
        <!-- 逾期罚息 -->
        <div class="info">
          <p>Denda Keterlambatan</p>
          <p>
            Rp{{
              formatNumberWithCommas(repaymentDetail.ovdrerdueInterestpenalty)
            }}
          </p>
        </div>
        <!-- 逾期违约金 -->
        <div class="info last">
          <p>Biaya Penundaan Keterlambatan</p>
          <p>
            Rp{{
              formatNumberWithCommas(repaymentDetail.ovdrerdueLiquidateddamages)
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  name: 'RepaymenDetail',
  data () {
    return {
      repaymentDetail: {}
    }
  },
  created () {
    this.getRepaymentDetail()
  },
  methods: {
    getRepaymentDetail () {
      request.post('homeDRInterface')
        .then(res => {
          this.repaymentDetail = res.bidrllDetails
        })
    }
  }
}
</script>

<style scoped lang="less">
.repaymen-detail {
}
.content {
  padding: 49px 40px;
  padding: 20px;
  height: 1449px;
  border-radius: 30px 30px 0px 0px;
  .top-block {
    height: 231px;
    padding-left: 50px;
    margin-bottom: 49px;
    background: url("~@/assets/img/huankuan_info_bg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .p1 {
      font-size: 30px;
      font-weight: normal;
      color: #ffffff;
    }

    .p2 {
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
    }
    .p3 {
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .detail-list {
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 104px;
      padding: 0 20px;
      border-bottom: 2px solid rgba(225, 225, 225, 0.2);
      p {
        font-size: 30px;
        font-weight: 500;
        color: #fff;
      }
    }
  }
}
</style>
